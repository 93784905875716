export const ascent_data = [
	{
		id: 1,
		title: 'B lobby/b main from B site',
		isActive: true,
		difficulty: 'Easy',
		side: 'Defending',
		essential: true,
		video: '431576728',
		image1: 'https://i.imgur.com/E4NbMfQ.png',
		image2: '',
		bounces: 1,
		charges: 1,
		map: 'https://i.imgur.com/L8bSkrL.jpg',
		result: "https://i.imgur.com/b7rNGTg.png",
		info:
			"Can cover mid/B lobby, as well as parts of B main, but doesn't fully cover either of them. Aim at top corner of the roof. "
	},
	{
		id: 14,
		title: 'A site from A main',
		isActive: false,
		difficulty: 'Medium',
		side: 'Attacking',
		essential: true,
		bounces: 0,
		charges: 1,
		video: '432604257',
		image1: 'https://i.imgur.com/fIPZZSS.jpg',
		image2: '',
		map: 'https://i.imgur.com/ebxttbg.jpg',
		result: "https://i.imgur.com/zXvYGnN.png",
		info:
			'Darts on A site should land here, it scans all the angles around the black box, as well as back of site. Align the bottom left of the charge meter with the stone as shown in the picture. '
	},
	{
		id: 2,
		title: 'Mid courtyard from mid cubby',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: false,
		bounces: 1,
		charges: 1,
		video: '431576892',
		image1: 'https://i.imgur.com/fqeNnRT.png',
		image2: '',
		map: 'https://i.imgur.com/gM8Rm7X.jpg',
		result: "https://i.imgur.com/7nou3yY.png",
		info:
			'This lineup will scan all of mid couryard from mid cubby, while staying hidden and out of sight. There are somy small blind spots, but for the most part, no one holds those positions if they are attacking. Aim where the 2 curves meet in the graffiti. '
	},
	
	{
		id: 13,
		title: 'Market from B main',
		isActive: false,
		difficulty: 'Easy',
		side: 'Attacking',
		essential: true,
		video: "432604384",
		image1: "https://i.imgur.com/qFKV4VZ.jpg",
		image2: "",
		bounces: 2, 
		charges: 1,
		map: "https://i.imgur.com/oq080aB.jpg",
		result: "https://i.imgur.com/PjcVzaN.png",
		info:
			"This lineup will clear the awper that's rotating from mid, they won't expect it since it's coming from behind them. Aim right in between the 2 yellow lines at the top of the window. "
	},
	{
		id: 3,
		title: 'Mid link from market',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: true,
		video: '431576817',
		bounces: 1,
		charges: 1,
		image1: 'https://i.imgur.com/1MmBX0u.png',
		image2: '',
		map: 'https://i.imgur.com/sranZUu.jpg',
		result: "https://i.imgur.com/2q1Ff50.png",
		info:
			"Lineup is for full scanning Mid link, but careful since it won't scan B lobby if you're against a team that slow pushes. Aim in the center right below the red cloth. "
	},
	{
		id: 4,
		title: 'Top mid from market',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: true,
		video: '431576644',
		image1: 'https://i.imgur.com/oKUZGU7.png',
		image2: '',
		bounces: 1, 
		charges: 3,
		map: 'https://i.imgur.com/H8KL2X9.jpg',
		result: "https://i.imgur.com/9RrxFRD.png",
		info:
			'This should be your staple dart for top of mid, but should be mixed up with a mid link lineup to avoid prefire. If they fast peek you, you can perform this same dart from the back of mid. '
	},
	{
		id: 5,
		title: 'Pizza/Mid bottom from mid top',
		isActive: false,
		difficulty: 'Hard',
		side: 'Attacking',
		essential: false,
		video: '432419685',
		bounces: 0,
		charges: 3,
		image1: "https://i.imgur.com/UssEkeE.jpg",
		map: 'https://i.imgur.com/Ytvx1X7.jpg',
		result: "https://i.imgur.com/TE4eThr.png",
		info:
			"Scans both mid bottom and pizza area, but it's difficult to pull off. Please practice it before hand, so you can position yourself anywhere against the wall, as long as you aim above the corner where it's going to land. Imagine a horizontal line that's slightly below the roof, and a veritcal line that's always above the corner. I think this is a vital dart for mid, but there's another mid that covers nearly the same area that's a lot easier. "
	},
	{
		id: 6,
		title: 'A site from A lobby',
		isActive: false,
		difficulty: 'Medium',
		side: 'Attacking',
		essential: true,
		video: '432603212',
		bounces: 0,
		charges: 1,
		image1: "https://i.imgur.com/cxVPe6k.png",
		result: "https://i.imgur.com/XrDq3aU.png",
		map: "https://i.imgur.com/ebxttbg.jpg",
		info:
			"Scans back of site, all round the metal box, close left and close right, as well as behind the wooden box. The only spots it does not scan is hell and heaven. Aim right where the pointy white thing is as shown in the picture. "
	},
	{
		id: 7,
		title: 'A site from A main',
		isActive: false,
		difficulty: 'Easy',
		side: 'Attacking',
		essential: true,
		video: '432419840',
		image1: "https://i.imgur.com/uC5cJYL.png",
		bounces: 1,
		charges: 2,
		map: "https://i.imgur.com/NHJFiZU.png",
		result: "https://i.imgur.com/CgiNGYy.png",
		info:
			"Similar to the previous one, but it sacrifices the metal box for right side of heaven. Position yourself so that pipe on the wall is inbetween the door, then aim right at the bottom of the pipe. Careful since it leaves the left site unchecked. "
	},
	{
		id: 8,
		title: 'A heaven from A main',
		isActive: false,
		difficulty: 'Easy',
		side: 'Attacking',
		essential: false,
		video: "432419840",
		bounces: 1,
		charges: 2,
		image1: "https://i.imgur.com/a4kOvpo.png",
		map: "https://i.imgur.com/NHJFiZU.png",
		info:
			'This lineup will scan back of heaven, as well as close right on the site. Aiming anywhere on the upper half of the wall will scan it.'
	},
	{
		id: 9,
		title: 'A lobby/top mid from A site',
		isActive: false,
		difficulty: 'Hard',
		side: 'Defending',
		essential: false,
		video: "432604158",
		image1: "https://i.imgur.com/mdkYGf4.jpg",
		bounces: 0, 
		charges: 1,
		map: "https://i.imgur.com/Oft1ut7.jpg",
		result: "https://i.imgur.com/OrQeo7G.png",
		info:
			"This lineup will reach back of mid, as well as A lobby. Line up the bottom of your hud so that it's touching the top left hand side of the stone above the entrance. "
	},
	{
		id: 10,
		title: 'Pizza/mid bottom from mid top',
		isActive: false,
		difficulty: 'Medium',
		side: 'Attacking',
		essential: false,
		video: "432603297",
		image1: "https://i.imgur.com/Bz0yqHR.png",
		image2: "https://i.imgur.com/OtRkGGn.png",
		bounces: 2, 
		charges: 3,
		map: "https://i.imgur.com/Ytvx1X7.jpg",
		result: "https://i.imgur.com/aGEmbKy.png",
		info:
			"An easier version of the lineup mentioned earlier, but it's easier for it to be shot down by someone defending. Mix it up with the previous mid dart. Align yourself against the back wall so that a slanted black line is showing. Then aim at the bottom part of that slant. "
	},
	{
		id: 11,
		title: 'Hell from heaven',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: false,
		video: "432604345",
		image1: "https://i.imgur.com/Bz0yqHR.png",
		image2: "https://i.imgur.com/OtRkGGn.png",
		bounces: 2, 
		charges: 3,
		map: "https://i.imgur.com/rwvtuFa.jpg",
		result: "https://i.imgur.com/Mq4GLEz.png",
		info:
			"This is a retake dart for when you know someone's in hell. You can wallbang them once it scans. Aiming aimwhere on the green box should bounce it into hell, but make sure your position is align the the box. "
	},
	{
		id: 12,
		title: 'Shed from B main',
		isActive: false,
		difficulty: 'Hard',
		side: 'Attacking',
		essential: true,
		video: "432604471",
		image1: "https://i.imgur.com/BjuiCGx.jpg",
		image2: "",
		bounces: 1, 
		charges: 3,
		map: "https://i.imgur.com/ZUG4kbD.jpg",
		result: "https://i.imgur.com/915GyJx.png",
		info:
			"This is one of the few hard, yet essential darts here. It's a very powerful dart that scans shed, the alley on close left, as well as right below market. Make sure you're crouching, and positioning the top left of your charge bar with the horizontal wood on the box, but make sure you're not flush next to it. put a tiny gap in between. "
	}
];
export const ascent_detail = {
	id: 1,
	title: 'B lobby/b main from B site',
	isActive: true,
	difficulty: 'Easy',
	side: 'Defending',
	essential: true,
	video: '431576728',
	image1: 'https://i.imgur.com/E4NbMfQ.png',
	image2: '',
	bounces: 1,
	charges: 1,
	map: 'https://i.imgur.com/L8bSkrL.jpg',
	result: "https://i.imgur.com/b7rNGTg.png",
	info:
		"Can cover mid/B lobby, as well as parts of B main, but doesn't fully cover either of them. Aim at top corner of the roof. "
};

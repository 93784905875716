export const haven_data = [
	{
		id: 1,
		title: 'Garden from A site',
		isActive: true,
		difficulty: 'Medium',
		side: 'Attacking',
		essential: true,
		video: '430192154',
		bounces: 0,
		charges: 1,
		image1: 'https://i.imgur.com/miCS1RO.png',
		image2: 'https://i.imgur.com/vzPjMSe.png',
		map: 'https://i.imgur.com/4jZEq6i.jpg',
		result: "https://i.imgur.com/cn7YdB3.png",
		info:
			"Pre round dart if you're holding sewers, it'll reach pretty deep back of spawn (I've scanned people holding C long angle), as well as covering garden pushes to A. Align yourself with again the big boxes in the first picture so that the edge of the box lines up with the edge corner of the intersecting wall. Then aim at the corner of the roof in the 2nd picture. Watch out of the red angles, especially the one next to the box since it won't scan those areas. It can scan window area, but don't count on it since losing line of sight is very easy. "
	},
	{
		id: 2,
		title: 'Mid window from B site (A connector side)',
		isActive: false,
		difficulty: 'Medium',
		side: 'Defending',
		essential: false,
		video: '429846569',
		bounces: 1,
		charges: 2,
		image1: 'https://i.imgur.com/sGRmHxN.png',
		image2: '',
		map: "https://i.imgur.com/4T6O1GL.jpg",
		result: "https://i.imgur.com/DElp82Z.png",
		info:
			"This dart lands on the window sill for mid window, which reveals a lot of mid/potentially grass, while also revealing window. Line up your crosshair with the bottom of the red text on the side of the metal container on B site. If you run, you should have enough time to peek with an awp. The great part about this dart is that if someone peeks up the courtyard, it will also spot them."
	},
	{
		id: 3,
		title: 'Mid window from B site (gong)',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: true,
		video: '429846506',
		bounces: 0,
		charges: 1,
		image1: 'https://i.imgur.com/hcW2puP.png',
		image2: '',
		map: "https://i.imgur.com/4T6O1GL.jpg",
		result: "https://i.imgur.com/vPxGst0.png",
		info:
			"Very similar to the A connector side, this one will also land on the window sill at mid. This one you can use if you're coming into B from C side. Unlike the A connector side one, you're not exposed and safe from pushes. Just aim at the center of the wood that spans across the door. Unfortunately, this dart travels faster, and you won't have enough time to peek like the other dart. Use this dart if you're holding garage and your B guy needs help holding B site."
	},
	{
		id: 4,
		title: 'A site/heaven from A lobby',
		isActive: false,
		difficulty: 'Hard',
		side: 'Attacking',
		essential: false,
		video: '429846086',
		bounces: 0,
		charges: 2,
		image1: 'https://i.imgur.com/gUdmiR6.png',
		image2: '',
		result: "https://i.imgur.com/7wGQni2.png",
		map: 'https://i.imgur.com/0kTUm0J.jpg',
		info:
			"This dart is great since it clears site while also clearing heaven. First line up the bottom if your hud with the black line on the yellow wall. Also line up the 2 little tips so they are in the center of those 2 intentions along the roof. There are a lot of red spots on the map to watch out for, but luckily no one defends the site from those angles. Requires material quality to be on medium. "
	},
	{
		id: 5,
		title: 'C lobby from C site',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: true,
		video: '429846285',
		bounces: 0,
		charges: 1,
		image1: "https://i.imgur.com/u40JUZ2.png",
		image2: "",
		result: "https://i.imgur.com/zYEhMW7.png",
		map: 'https://i.imgur.com/mAJRN79.jpg',
		info:
			'Great for teams with slow pushes into garage and C long, while being very quick to execute. Aim at the corner of roof in the picture.'
	},
	{
		id: 6,
		title: 'C long/C Lobby from C site',
		isActive: false,
		difficulty: 'Medium',
		side: 'Defending',
		essential: false,
		video: '429846421',
		bounces: 2,
		charges: 3,
		image1: "https://i.imgur.com/3BHbkOj.png",
		image2: "",
		map: 'https://i.imgur.com/SZ4B7UU.jpg',
		result: "https://i.imgur.com/ksTLOxc.png",
		info:
			"This dart reveals C long while also getting C lobby. In order for this dart to land, you need to aim as close as possible to the corner of the small section protruding from the wall, and aim right below the brown wood. It doesn't reveal all of C lobby, but it reveals enough from slow pushers. "
	},
	{
		id: 7,
		title: 'Garage from attacker spawn',
		isActive: false,
		difficulty: 'Easy',
		side: 'Attacking',
		essential: true,
		video: "432704455",
		bounces: 2,
		charges: 3,
		image1: "https://i.imgur.com/whFPDLL.png",
		result: "https://i.imgur.com/veMQLqe.png",
		map: "https://i.imgur.com/gy0eKkM.jpg",
		info:
			"This dart isn't meant to cover garage window, it's meant to cover the connector between the connector between garage and C site, while covering the rest of garage. Aim about half way up from the wall. Be careful for the red spot, since it won't scan that area, and it's a very common off angle hold spot. "
	},
	{
		id: 8,
		title: 'B site from garden',
		isActive: false,
		difficulty: 'Hard',
		side: 'Attacking',
		essential: false,
		video: "429845924",
		bounces: 2,
		charges: 2,
		image1: "https://i.imgur.com/FSLaKE7.png",
		image2: "",
		map: "https://i.imgur.com/0BpWsWT.jpg",
		result: "https://i.imgur.com/9PdI7eS.png",
		info:
			"A very very difficult dart that I can't replicate 100% of the time, but I figure I add it because it's unique and very useful for someone who wants to master it. You can try to align yourself with the yellow spots on the image below, but it's still very difficul for me. It lands in the best spot on B, with almost not detection until it's too late. I won't even bother explaining how to do this one because I can't even consistently replicate it. But this dart with 2 brim smokes on either side of the metal container is a free entrance to B. Thanks to redditor u/KhigenNA for finding this dart!"
	},
	{
		id: 9,
		title: 'A lobby/garden from A tunnel/A site',
		isActive: false,
		difficulty: 'Hard',
		side: 'Defending',
		essential: false,
		video: "429845998",
		bounces: 0,
		charges: 2,
		image1: "https://i.imgur.com/r6UI8dl.png",
		image2: "",
		result: "https://i.imgur.com/fqYZ794.png",
		map: "https://i.imgur.com/f1yeA0D.jpg",
		info:
			"Great dart if you're holding sewers since it reveals A lobby, as well as some parts of garden. Align the tip of your shock dart icon on your HUD with the roof tile that protrudes out the most. Make sure the tip is exactly in the corner as shown in the image. As you can see in the map, it has a lot of blind spots, so be careful when using this dart not to bait your long player. Thanks to redditor u/KhigenNA for finding this dart!"
	}
	,
	{
		id: 10,
		title: 'Grass from C link',
		isActive: false,
		difficulty: 'Medium',
		side: 'Defending',
		essential: true,
		video: "430529895",
		bounces: 1,
		charges: 2,
		image1: "https://i.imgur.com/KykLPwu.png",
		image2: "https://i.imgur.com/v8Xttiw.png",
		result: "https://i.imgur.com/p2efeaf.png",
		map: "https://i.imgur.com/OwavSsG.jpg",
		info:
			'Very useful dart for catching the garage pushers off guard, while also holding courtyard for your mid player. It reveals a lot of grass, while also potentially revealing into their spawn. Move along the back wall until you see a small opening where you see the wall at mid. Aim very slightly above the shadow. Careful for the tiny red on the map, but this arrow lands so fast, it should get a pulse off before they move. '
	},
	{
		id: 11,
		title: 'Attacker spawn from C site',
		isActive: false,
		difficulty: 'Hard',
		side: 'Defending',
		essential: true,
		video: "430529785",
		bounces: 0,
		charges: 2,
		image1: "https://i.imgur.com/RLn7eFk.png",
		image2: "https://i.imgur.com/VDB6Cg7.jpg",
		result: "https://i.imgur.com/TESgO2K.png",
		map: "https://i.imgur.com/wQbaVOx.jpg",
		info:
			"This dart reveals the entire back side of their spawn. You can reveal slow pushers from C, middle, garage, and A. It has a long travel time, but it comes down to fast, it's virtually undetected until it lands. Locate the small block in the 1st image, and align the tip of your shock bolt icon with the bottom right hand corner of that block. "
	},
	{
		id: 12,
		title: 'C site from C lobby (back C)',
		isActive: false,
		difficulty: 'Medium',
		side: 'Attacking',
		essential: true,
		video: "430912759",
		bounces: 1,
		charges: 2,
		image1: "https://i.imgur.com/bknngBE.jpg",
		image2: "https://i.imgur.com/qQoPd0q.jpg",
		result: "https://i.imgur.com/Ni44gGF.png",
		map: "https://i.imgur.com/KQvNrN8.jpg",
		info:
			"Really solid dart that should be mixed in with your garage push and C long push. It covers back of site, but most importantly, it covers the back left of the site where an awper can hold. Watch where I move to at the end of the video, make sure your darts clear that spot, very common awp spot. Align yourself so that the box is flush with the wall in the first image. Aim so that the left of the owl drone icon is flush with the small mountain in the background. "
	},{
		id: 13,
		title: 'C site from C lobby (platform)',
		isActive: false,
		difficulty: 'Easy',
		side: 'Attacking',
		essential: true,
		video: "430912690",
		bounces: 0,
		charges: 1,
		image1: "https://i.imgur.com/YltdB0I.png",
		result: "https://i.imgur.com/hPMoOBw.png",
		map: "https://i.imgur.com/mMXTgHg.jpg",
		info:
			"Similar to the last one, but a faster travel time. It lands on plat, so someone can be awping from the right of the back box, and it won't scan there. Aim right where the blue flag is hanging."
	},
	{
		id: 15,
		title: "A site from A link",
		isActive: false,
		difficulty: 'Hard',
		side: 'Defending',
		essential: true,
		video: "432707035",
		bounces: 0,
		charges: 0,
		image1: "https://i.imgur.com/yTzHDW6.jpg",
		result: "https://i.imgur.com/DKchKKq.png",
		map: "https://i.imgur.com/XJRxsC0.jpg",
		info:
			"Very great retake dart that covers 100% of hell, as well as other hiding spots. Align the corner of the box in the HUD with the corner of the window as shown in the picture below. Don't put it right next to it, make sure there's a very tiny gap. "
	},{
		id: 14,
		title: 'C site from C Link',
		isActive: false,
		difficulty: 'Easy',
		side: 'Defending',
		essential: true,
		video: "432704497",
		bounces: 1,
		charges: 2,
		image1: "https://i.imgur.com/jCjIlJh.png",
		result: "https://i.imgur.com/jwTVHtb.png",
		map: "https://i.imgur.com/Ih0Deha.jpg",
		info:
			"Easy retake arrow that lands in the back of C site when you're retaking. Aim along the wall about half way up from the shadow. "
	},
	{
		id: 16,
		title: 'A site/heaven from A long',
		isActive: false,
		difficulty: 'Medium',
		side: 'Attacking',
		essential: true,
		video: "432752209",
		bounces: 0,
		charges: 1,
		image1: "https://i.imgur.com/v9IwnSZ.jpg",
		result: "https://i.imgur.com/cCpln7i.png",
		map: "https://i.imgur.com/0kTUm0J.jpg",
		info:
			"Great dart for pushing into site, it clears heaven as well as a good portion of site. Make sure the top left corner of your charge bar lines up right in between the two rocks as shown in the image. Thanks to redditor u/jhn2 (discord JOHN#1154) for finding this dart!"
	},
];
export const haven_detail = {
	id: 1,
	title: 'Garden from A site',
	isActive: true,
	difficulty: 'Medium',
	side: 'Attacking',
	essential: true,
	video: '430192154',
	bounces: 0,
	charges: 1,
	image1: 'https://i.imgur.com/miCS1RO.png',
	image2: 'https://i.imgur.com/vzPjMSe.png',
	map: 'https://i.imgur.com/4jZEq6i.jpg',
	result: "https://i.imgur.com/cn7YdB3.png",
	info:
		"Pre round dart if you're holding sewers, it'll reach pretty deep back of spawn (I've scanned people holding C long angle), as well as covering garden pushes to A. Align yourself with again the big boxes in the first picture so that the edge of the box lines up with the edge corner of the intersecting wall. Then aim at the corner of the roof in the 2nd picture. Watch out of the red angles, especially the one next to the box since it won't scan those areas. It can scan window area, but don't count on it since losing line of sight is very easy. "
};

export const logs_data = [
	{
		id: 1,
        date: '6/23/2020',
        description: 'Site launched with content.',
	
	},
	{
		id: 2,
        date: '6/25/2020',
        description: "Added 'C site from C link' on Haven since they updated the maps. Added 'A site/heaven from A long'.",
	
	},
	{
		id: 3,
        date: '6/28/2020',
        description: "Updated 'Hookah from B short' since they updated the map. ",
	
	},
	{
		id: 4,
        date: '11/18/2020',
        description: "I've been super busy with work, I might add an icebox map soon. I've been playing Valorant lately. Anyways, fixed some bugs thanks to rpkonz.",
	
	},
	
];